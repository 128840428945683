var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "sw-dropdown",
        { attrs: { "is-show": _vm.isShow, variant: "search-dropdown" } },
        [
          _c(
            "sw-input",
            {
              attrs: {
                slot: "activator",
                placeholder: _vm.$t("global_search.search"),
                variant: "search-input"
              },
              on: { input: _vm.throttledMethod },
              slot: "activator",
              model: {
                value: _vm.name,
                callback: function($$v) {
                  _vm.name = $$v
                },
                expression: "name"
              }
            },
            [
              _c("search-icon", {
                staticClass: "h-5 m-1 text-websheriff-alt",
                attrs: { slot: "leftIcon" },
                slot: "leftIcon"
              }),
              _vm._v(" "),
              _c("loading-icon", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isLoading,
                    expression: "isLoading"
                  }
                ],
                staticClass:
                  "absolute right-0 h-5 m-1 animate-spin text-primary-400",
                attrs: { slot: "rightIcon" },
                slot: "rightIcon"
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "w-64 h-40 overflow-y-scroll box" }, [
            _vm.getCustomerList.length > 0 && !_vm.isLoading
              ? _c(
                  "div",
                  [
                    _c(
                      "label",
                      { staticClass: "text-xs text-gray-400 uppercase" },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("global_search.customers")) +
                            "\n        "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.getCustomerList, function(d) {
                      return _c(
                        "router-link",
                        {
                          key: d.id,
                          attrs: { to: "/admin/customers/" + d.id + "/view" }
                        },
                        [
                          _c("sw-dropdown-item", [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "flex items-center justify-center w-8 h-8 mr-4 text-xs font-semibold bg-gray-300 rounded-full text-primary-500"
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.initGenerator(d.name)) +
                                    "\n            "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            d.contact_name
                              ? _c("div", { staticClass: "flex flex-col" }, [
                                  _c(
                                    "span",
                                    { staticClass: "text-sm text-black" },
                                    [_vm._v(_vm._s(d.name))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "text-xs text-websheriff-alt"
                                    },
                                    [_vm._v(_vm._s(d.contact_name))]
                                  )
                                ])
                              : _c(
                                  "div",
                                  { staticClass: "flex items-center" },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "text-sm text-black" },
                                      [_vm._v(_vm._s(d.name))]
                                    )
                                  ]
                                )
                          ])
                        ],
                        1
                      )
                    })
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.getUserList.length > 0 && !_vm.isLoading
              ? _c(
                  "div",
                  [
                    _c(
                      "label",
                      { staticClass: "text-xs text-gray-400 uppercase" },
                      [_vm._v(_vm._s(_vm.$t("global_search.users")))]
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.getUserList, function(d) {
                      return _c(
                        "router-link",
                        {
                          key: d.id,
                          attrs: { to: "/admin/users/" + d.id + "/edit" }
                        },
                        [
                          _c("sw-dropdown-item", [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "flex items-center justify-center w-8 h-8 mr-4 text-xs font-semibold bg-gray-300 rounded-full text-primary-500"
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.initGenerator(d.name)) +
                                    "\n            "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "flex items-center" }, [
                              _c(
                                "span",
                                { staticClass: "text-sm text-black" },
                                [_vm._v(_vm._s(d.name))]
                              )
                            ])
                          ])
                        ],
                        1
                      )
                    })
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.getUserList.length === 0 &&
            _vm.getCustomerList.length === 0 &&
            !_vm.isLoading
              ? _c("div", [
                  _c(
                    "span",
                    {
                      staticClass:
                        "flex items-center justify-center text-sm font-normal text-websheriff-alt"
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("global_search.no_results_found")) +
                          "\n        "
                      )
                    ]
                  )
                ])
              : _vm._e()
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }