var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex items-center justify-between w-full mt-2 text-sm" },
    [
      _c(
        "label",
        {
          staticClass: "font-semibold leading-5 text-websheriff-alt uppercase"
        },
        [
          _vm._v(
            "\n    " +
              _vm._s(_vm.tax.name) +
              " (" +
              _vm._s(_vm.tax.percent) +
              "%)\n  "
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "label",
        { staticClass: "flex items-center justify-center text-lg text-black" },
        [
          _c("div", {
            domProps: {
              innerHTML: _vm._s(
                _vm.$utils.formatMoney(_vm.tax.amount, _vm.currency)
              )
            }
          }),
          _vm._v(" "),
          _c("trash-icon", {
            staticClass: "h-5 ml-2",
            on: {
              click: function($event) {
                return _vm.$emit("remove", _vm.index)
              }
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }