var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "grid gap-6 sm:grid-cols-2 lg:grid-cols-9 xl:gap-8" },
    [
      _c(
        "router-link",
        {
          staticClass:
            "relative flex justify-between p-3 bg-white rounded shadow hover:bg-gray-100 lg:col-span-3 xl:p-4",
          attrs: { slot: "item-title", to: "/admin/invoices" },
          slot: "item-title"
        },
        [
          _c("div", [
            _vm.getDashboardDataLoaded
              ? _c(
                  "span",
                  {
                    staticClass:
                      "text-xl font-semibold leading-tight text-black xl:text-3xl"
                  },
                  [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$utils.formatMoney(
                            _vm.getTotalDueAmount,
                            _vm.defaultCurrency
                          )
                        )
                      }
                    })
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass:
                  "block mt-1 text-sm leading-tight text-websheriff-alt xl:text-lg"
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("dashboard.cards.due_amount")) +
                    "\n      "
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex items-center" },
            [_c("dollar-icon", { staticClass: "w-10 h-10 xl:w-12 xl:h-12" })],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "router-link",
        {
          staticClass:
            "relative flex justify-between p-3 bg-white rounded shadow hover:bg-gray-100 lg:col-span-2 xl:p-4",
          attrs: { slot: "item-title", to: "/admin/customers" },
          slot: "item-title"
        },
        [
          _c("div", [
            _vm.getDashboardDataLoaded
              ? _c(
                  "span",
                  {
                    staticClass:
                      "text-xl font-semibold leading-tight text-black xl:text-3xl"
                  },
                  [_vm._v("\n        " + _vm._s(_vm.getContacts) + "\n      ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass:
                  "block mt-1 text-sm leading-tight text-websheriff-alt xl:text-lg"
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("dashboard.cards.customers")) +
                    "\n      "
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex items-center" },
            [_c("contact-icon", { staticClass: "w-10 h-10 xl:w-12 xl:h-12" })],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "router-link",
        {
          staticClass:
            "relative flex justify-between p-3 bg-white rounded shadow hover:bg-gray-100 lg:col-span-2 xl:p-4",
          attrs: { slot: "item-title", to: "/admin/invoices" },
          slot: "item-title"
        },
        [
          _c("div", [
            _vm.getDashboardDataLoaded
              ? _c(
                  "span",
                  {
                    staticClass:
                      "text-xl font-semibold leading-tight text-black xl:text-3xl"
                  },
                  [_vm._v("\n        " + _vm._s(_vm.getInvoices) + "\n      ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass:
                  "block mt-1 text-sm leading-tight text-websheriff-alt xl:text-lg"
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("dashboard.cards.invoices")) +
                    "\n      "
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex items-center" },
            [_c("invoice-icon", { staticClass: "w-10 h-10 xl:w-12 xl:h-12" })],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "router-link",
        {
          staticClass:
            "relative flex justify-between p-3 bg-white rounded shadow hover:bg-gray-100 lg:col-span-2 xl:p-4",
          attrs: { slot: "item-title", to: "/admin/estimates" },
          slot: "item-title"
        },
        [
          _c("div", [
            _vm.getDashboardDataLoaded
              ? _c(
                  "span",
                  {
                    staticClass:
                      "text-xl font-semibold leading-tight text-black xl:text-3xl"
                  },
                  [_vm._v("\n        " + _vm._s(_vm.getEstimates) + "\n      ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass:
                  "block mt-1 text-sm leading-tight text-websheriff-alt xl:text-lg"
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("dashboard.cards.estimates")) +
                    "\n      "
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex items-center" },
            [_c("estimate-icon", { staticClass: "w-10 h-10 xl:w-12 xl:h-12" })],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }