var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "relative" },
    [
      _vm.isRequestOnGoing
        ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "sw-card",
        { attrs: { variant: "setting-card" } },
        [
          _c("template", { slot: "header" }, [
            _c("h6", { staticClass: "sw-section-title" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("settings.notification.title")) +
                  "\n      "
              )
            ]),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "mt-2 text-sm leading-snug text-websheriff-alt",
                staticStyle: { "max-width": "680px" }
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("settings.notification.description")) +
                    "\n      "
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c(
            "form",
            {
              attrs: { action: "" },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.saveEmail()
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "grid-cols-2 col-span-1" },
                [
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "my-2",
                      attrs: {
                        label: _vm.$t("settings.notification.email"),
                        error: _vm.notificationEmailError,
                        required: ""
                      }
                    },
                    [
                      _c("sw-input", {
                        attrs: {
                          invalid: _vm.$v.notification_email.$error,
                          placeholder: _vm.$tc(
                            "settings.notification.please_enter_email"
                          ),
                          type: "text",
                          name: "notification_email",
                          icon: "envelope"
                        },
                        on: {
                          input: function($event) {
                            return _vm.$v.notification_email.$touch()
                          }
                        },
                        model: {
                          value: _vm.notification_email,
                          callback: function($$v) {
                            _vm.notification_email =
                              typeof $$v === "string" ? $$v.trim() : $$v
                          },
                          expression: "notification_email"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-button",
                    {
                      staticClass: "my-6",
                      attrs: {
                        disabled: _vm.isLoading,
                        loading: _vm.isLoading,
                        variant: "primary",
                        type: "submit"
                      }
                    },
                    [
                      !_vm.isLoading
                        ? _c("save-icon", { staticClass: "mr-2 -ml-1" })
                        : _vm._e(),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$tc("settings.notification.save")) +
                          "\n        "
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c("sw-divider", { staticClass: "mt-1 mb-6" }),
          _vm._v(" "),
          _c("div", { staticClass: "flex mt-3 mb-4" }, [
            _c(
              "div",
              { staticClass: "relative w-12" },
              [
                _c("sw-switch", {
                  staticClass: "absolute",
                  staticStyle: { top: "-20px" },
                  on: { change: _vm.setInvoiceViewd },
                  model: {
                    value: _vm.notify_invoice_viewed,
                    callback: function($$v) {
                      _vm.notify_invoice_viewed = $$v
                    },
                    expression: "notify_invoice_viewed"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "ml-4" }, [
              _c(
                "p",
                {
                  staticClass:
                    "p-0 mb-1 text-base leading-snug text-black box-title"
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("settings.notification.invoice_viewed")) +
                      "\n        "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass:
                    "p-0 m-0 text-xs leading-tight text-websheriff-alt",
                  staticStyle: { "max-width": "480px" }
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t("settings.notification.invoice_viewed_desc")
                      ) +
                      "\n        "
                  )
                ]
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "flex mb-2" }, [
            _c(
              "div",
              { staticClass: "relative w-12" },
              [
                _c("sw-switch", {
                  staticClass: "absolute",
                  staticStyle: { top: "-20px" },
                  on: { change: _vm.setEstimateViewd },
                  model: {
                    value: _vm.notify_estimate_viewed,
                    callback: function($$v) {
                      _vm.notify_estimate_viewed = $$v
                    },
                    expression: "notify_estimate_viewed"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "ml-4" }, [
              _c(
                "p",
                {
                  staticClass:
                    "p-0 mb-1 text-base leading-snug text-black box-title"
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("settings.notification.estimate_viewed")) +
                      "\n        "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass:
                    "p-0 m-0 text-xs leading-tight text-websheriff-alt",
                  staticStyle: { "max-width": "480px" }
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t("settings.notification.estimate_viewed_desc")
                      ) +
                      "\n        "
                  )
                ]
              )
            ])
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }