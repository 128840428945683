var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login" },
    [
      _c("base-notification"),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "card" }, [_c("router-view")], 1),
      _vm._v(" "),
      _c(
        "router-link",
        { staticClass: "forgot", attrs: { to: "forgot-password" } },
        [_vm._v("\n    Forgot your password?\n  ")]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "intro" }, [
      _c("h3", [_vm._v("Go rob another one...")]),
      _vm._v(" "),
      _c("h1", [_vm._v("Bank by Websheriff")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }