var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.saveEmailConfig($event)
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "grid grid-cols-1 gap-4 mb-4 md:grid-cols-2 md:mb-6" },
        [
          _c(
            "sw-input-group",
            {
              attrs: {
                label: _vm.$t("wizard.mail.driver"),
                error: _vm.driverError,
                required: ""
              }
            },
            [
              _c("sw-select", {
                attrs: {
                  invalid: _vm.$v.mailConfigData.mail_driver.$error,
                  options: _vm.mailDrivers,
                  searchable: true,
                  "allow-empty": false,
                  "show-labels": false
                },
                on: { input: _vm.onChangeDriver },
                model: {
                  value: _vm.mailConfigData.mail_driver,
                  callback: function($$v) {
                    _vm.$set(_vm.mailConfigData, "mail_driver", $$v)
                  },
                  expression: "mailConfigData.mail_driver"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              attrs: {
                label: _vm.$t("wizard.mail.host"),
                error: _vm.hostError,
                required: ""
              }
            },
            [
              _c("sw-input", {
                attrs: {
                  invalid: _vm.$v.mailConfigData.mail_host.$error,
                  type: "text",
                  name: "mail_host"
                },
                on: {
                  input: function($event) {
                    return _vm.$v.mailConfigData.mail_host.$touch()
                  }
                },
                model: {
                  value: _vm.mailConfigData.mail_host,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.mailConfigData,
                      "mail_host",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "mailConfigData.mail_host"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "grid grid-cols-1 gap-4 mb-4 md:grid-cols-2 md:mb-6" },
        [
          _c(
            "sw-input-group",
            {
              attrs: {
                label: _vm.$t("wizard.mail.port"),
                error: _vm.portError,
                required: ""
              }
            },
            [
              _c("sw-input", {
                attrs: {
                  invalid: _vm.$v.mailConfigData.mail_port.$error,
                  type: "text",
                  name: "mail_port"
                },
                on: {
                  input: function($event) {
                    return _vm.$v.mailConfigData.mail_port.$touch()
                  }
                },
                model: {
                  value: _vm.mailConfigData.mail_port,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.mailConfigData,
                      "mail_port",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "mailConfigData.mail_port"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              attrs: {
                label: _vm.$t("wizard.mail.encryption"),
                error: _vm.encryptionError,
                required: ""
              }
            },
            [
              _c("sw-select", {
                attrs: {
                  invalid: _vm.$v.mailConfigData.mail_encryption.$error,
                  options: _vm.encryptions,
                  searchable: true,
                  "show-labels": false
                },
                on: {
                  input: function($event) {
                    return _vm.$v.mailConfigData.mail_encryption.$touch()
                  }
                },
                model: {
                  value: _vm.mailConfigData.mail_encryption,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.mailConfigData,
                      "mail_encryption",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "mailConfigData.mail_encryption"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "grid grid-cols-1 gap-4 mb-4 md:grid-cols-2 md:mb-6" },
        [
          _c(
            "sw-input-group",
            {
              attrs: {
                label: _vm.$t("wizard.mail.from_mail"),
                error: _vm.fromEmailError,
                required: ""
              }
            },
            [
              _c("sw-input", {
                attrs: {
                  invalid: _vm.$v.mailConfigData.from_mail.$error,
                  type: "text",
                  name: "from_mail"
                },
                on: {
                  input: function($event) {
                    return _vm.$v.mailConfigData.from_mail.$touch()
                  }
                },
                model: {
                  value: _vm.mailConfigData.from_mail,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.mailConfigData,
                      "from_mail",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "mailConfigData.from_mail"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              attrs: {
                label: _vm.$t("wizard.mail.from_name"),
                error: _vm.fromNameError,
                required: ""
              }
            },
            [
              _c("sw-input", {
                attrs: {
                  invalid: _vm.$v.mailConfigData.from_name.$error,
                  type: "text",
                  name: "name"
                },
                on: {
                  input: function($event) {
                    return _vm.$v.mailConfigData.from_name.$touch()
                  }
                },
                model: {
                  value: _vm.mailConfigData.from_name,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.mailConfigData,
                      "from_name",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "mailConfigData.from_name"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "grid grid-cols-1 gap-4 mb-6 md:grid-cols-2" },
        [
          _c(
            "sw-input-group",
            {
              attrs: {
                label: _vm.$t("wizard.mail.ses_key"),
                error: _vm.keyError,
                required: ""
              }
            },
            [
              _c("sw-input", {
                attrs: {
                  invalid: _vm.$v.mailConfigData.mail_ses_key.$error,
                  type: "text",
                  name: "mail_ses_key"
                },
                on: {
                  input: function($event) {
                    return _vm.$v.mailConfigData.mail_ses_key.$touch()
                  }
                },
                model: {
                  value: _vm.mailConfigData.mail_ses_key,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.mailConfigData,
                      "mail_ses_key",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "mailConfigData.mail_ses_key"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              attrs: {
                label: _vm.$t("wizard.mail.ses_secret"),
                error: _vm.secretError,
                required: ""
              }
            },
            [
              _c("sw-input", {
                attrs: {
                  invalid: _vm.$v.mailConfigData.mail_ses_secret.$error,
                  type: _vm.getInputType,
                  name: "mail_ses_secret"
                },
                on: {
                  input: function($event) {
                    return _vm.$v.mailConfigData.mail_ses_secret.$touch()
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "rightIcon",
                    fn: function() {
                      return [
                        _vm.isShowPassword
                          ? _c("eye-off-icon", {
                              staticClass:
                                "w-5 h-5 mr-1 text-websheriff-alt cursor-pointer",
                              on: {
                                click: function($event) {
                                  _vm.isShowPassword = !_vm.isShowPassword
                                }
                              }
                            })
                          : _c("eye-icon", {
                              staticClass:
                                "w-5 h-5 mr-1 text-websheriff-alt cursor-pointer",
                              on: {
                                click: function($event) {
                                  _vm.isShowPassword = !_vm.isShowPassword
                                }
                              }
                            })
                      ]
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.mailConfigData.mail_ses_secret,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.mailConfigData,
                      "mail_ses_secret",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "mailConfigData.mail_ses_secret"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "sw-button",
        {
          staticClass: "mt-4",
          attrs: {
            loading: _vm.loading,
            disabled: _vm.loading,
            variant: "primary",
            type: "submit"
          }
        },
        [
          !_vm.loading ? _c("save-icon", { staticClass: "mr-2" }) : _vm._e(),
          _vm._v("\n    " + _vm._s(_vm.$t("general.save")) + "\n  ")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }