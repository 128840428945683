var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "sw-card",
    { attrs: { variant: "setting-card" } },
    [
      _c(
        "div",
        {
          staticClass: "flex flex-wrap justify-between lg:flex-nowrap",
          attrs: { slot: "header" },
          slot: "header"
        },
        [
          _c("div", [
            _c("h6", { staticClass: "sw-section-title" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("settings.customization.notes.title")) +
                  "\n      "
              )
            ]),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "mt-2 text-sm leading-snug text-websheriff-alt",
                staticStyle: { "max-width": "680px" }
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("settings.customization.notes.description")) +
                    "\n      "
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt-4 lg:mt-0 lg:ml-2" },
            [
              _c(
                "sw-button",
                {
                  attrs: { size: "lg", variant: "primary-outline" },
                  on: { click: _vm.openNoteSelectModal }
                },
                [
                  _c("plus-icon", { staticClass: "w-6 h-6 mr-1 -ml-2" }),
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("settings.customization.notes.add_note")) +
                      "\n      "
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "sw-table-component",
        {
          ref: "table",
          attrs: { "show-filter": false, data: _vm.fetchData, variant: "gray" }
        },
        [
          _c("sw-table-column", {
            attrs: {
              label: _vm.$t("settings.customization.notes.name"),
              show: "name"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(row) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.$t("settings.customization.notes.name"))
                      )
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "mt-6" }, [
                      _vm._v(_vm._s(row.name))
                    ])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("sw-table-column", {
            attrs: {
              label: _vm.$t("settings.customization.notes.type"),
              show: "type"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(row) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.$t("settings.customization.notes.type"))
                      )
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "mt-6" }, [
                      _vm._v(_vm._s(row.type))
                    ])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("sw-table-column", {
            attrs: {
              sortable: false,
              filterable: false,
              "cell-class": "action-dropdown"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(row) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("settings.tax_types.action")))
                    ]),
                    _vm._v(" "),
                    _c(
                      "sw-dropdown",
                      [
                        _c("dot-icon", {
                          staticClass: "h-5",
                          attrs: { slot: "activator" },
                          slot: "activator"
                        }),
                        _vm._v(" "),
                        _c(
                          "sw-dropdown-item",
                          {
                            on: {
                              click: function($event) {
                                return _vm.editNote(row)
                              }
                            }
                          },
                          [
                            _c("pencil-icon", {
                              staticClass: "h-5 mr-3 text-gray-600"
                            }),
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("general.edit")) +
                                "\n          "
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "sw-dropdown-item",
                          {
                            on: {
                              click: function($event) {
                                return _vm.removeNote(row.id)
                              }
                            }
                          },
                          [
                            _c("trash-icon", {
                              staticClass: "h-5 mr-3 text-gray-600"
                            }),
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("general.delete")) +
                                "\n          "
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }