var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    {
      attrs: {
        "enter-class":
          "translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2 ",
        "enter-active-class": "transition duration-300 ease-out transform",
        "enter-to-class":
          "duration-300 translate-y-0 opacity-100 sm:translate-x-0",
        "leave-active-class": "transition duration-100 ease-in",
        "leave-class": "duration-200 opacity-100",
        "leave-to-class": "duration-200 opacity-0"
      }
    },
    [
      _vm.notificationActive
        ? _c(
            "div",
            {
              staticClass:
                "fixed inset-0 z-50 flex items-end justify-center px-4 py-6 pointer-events-none sm:p-6 sm:items-start sm:justify-end"
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "w-full max-w-sm rounded-lg shadow-lg cursor-pointer pointer-events-auto",
                  class: _vm.success || _vm.info ? "bg-white" : "bg-red-50",
                  on: { click: _vm.hideNotification }
                },
                [
                  _c(
                    "div",
                    { staticClass: "overflow-hidden rounded-lg shadow-xs" },
                    [
                      _c("div", { staticClass: "p-4" }, [
                        _c("div", { staticClass: "flex items-start" }, [
                          _c(
                            "div",
                            { staticClass: "flex-shrink-0" },
                            [
                              _vm.success
                                ? _c(
                                    "svg",
                                    {
                                      staticClass: "w-6 h-6 text-green-400",
                                      attrs: {
                                        fill: "none",
                                        viewBox: "0 0 24 24",
                                        stroke: "currentColor"
                                      }
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          "stroke-linecap": "round",
                                          "stroke-linejoin": "round",
                                          "stroke-width": "2",
                                          d:
                                            "M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                        }
                                      })
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.info
                                ? _c("exclamation-circle-icon", {
                                    staticClass: "w-6 h-6 text-blue-400"
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.error
                                ? _c(
                                    "svg",
                                    {
                                      staticClass: "w-6 h-6 text-red-400",
                                      attrs: {
                                        fill: "currentColor",
                                        viewBox: "0 0 24 24"
                                      }
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          "fill-rule": "evenodd",
                                          d:
                                            "M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z",
                                          "clip-rule": "evenodd"
                                        }
                                      })
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "flex-1 w-0 ml-3" }, [
                            _c(
                              "p",
                              {
                                class:
                                  "text-sm leading-5 font-medium " +
                                  (_vm.success || _vm.info
                                    ? "text-gray-900"
                                    : "text-red-800")
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.notificationTitle
                                        ? _vm.notificationTitle
                                        : _vm.success
                                        ? "Success!"
                                        : "Error"
                                    ) +
                                    "\n              "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "p",
                              {
                                class:
                                  "mt-1 text-sm leading-5 " +
                                  (_vm.success || _vm.info
                                    ? "text-websheriff-alt"
                                    : "text-red-700")
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.notificationMessage
                                        ? _vm.notificationMessage
                                        : _vm.success
                                        ? "Successful"
                                        : "Somthing went wrong"
                                    ) +
                                    "\n              "
                                )
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "flex flex-shrink-0" }, [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "inline-flex w-5 h-5 transition duration-150 ease-in-out focus:outline-none",
                                class:
                                  _vm.success || _vm.info
                                    ? " text-gray-400 focus:text-websheriff-alt"
                                    : "text-red-400 focus:text-red-500",
                                on: { click: _vm.hideNotification }
                              },
                              [_c("x-icon")],
                              1
                            )
                          ])
                        ])
                      ])
                    ]
                  )
                ]
              )
            ]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }