var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      attrs: { id: "loginForm" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.validateBeforeSubmit($event)
        }
      }
    },
    [
      _c(
        "sw-input-group",
        {
          attrs: {
            label: _vm.$t("login.email"),
            error: _vm.emailError,
            required: ""
          }
        },
        [
          _c("sw-input", {
            attrs: {
              invalid: _vm.$v.loginData.email.$error,
              placeholder: "johndoe@company.nl",
              focus: "",
              type: "email",
              name: "email"
            },
            on: {
              input: function($event) {
                return _vm.$v.loginData.email.$touch()
              }
            },
            model: {
              value: _vm.loginData.email,
              callback: function($$v) {
                _vm.$set(_vm.loginData, "email", $$v)
              },
              expression: "loginData.email"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "sw-input-group",
        {
          attrs: {
            label: _vm.$t("login.password"),
            error: _vm.passwordError,
            required: ""
          }
        },
        [
          _c("sw-input", {
            attrs: {
              invalid: _vm.$v.loginData.password.$error,
              placeholder: "********",
              type: _vm.getInputType,
              name: "password"
            },
            on: {
              input: function($event) {
                return _vm.$v.loginData.password.$touch()
              }
            },
            scopedSlots: _vm._u([
              {
                key: "rightIcon",
                fn: function() {
                  return [
                    _vm.isShowPassword
                      ? _c("eye-off-icon", {
                          staticClass:
                            "w-5 h-5 mr-1 text-websheriff-alt cursor-pointer",
                          on: {
                            click: function($event) {
                              _vm.isShowPassword = !_vm.isShowPassword
                            }
                          }
                        })
                      : _c("eye-icon", {
                          staticClass:
                            "w-5 h-5 mr-1 text-websheriff-alt cursor-pointer",
                          on: {
                            click: function($event) {
                              _vm.isShowPassword = !_vm.isShowPassword
                            }
                          }
                        })
                  ]
                },
                proxy: true
              }
            ]),
            model: {
              value: _vm.loginData.password,
              callback: function($$v) {
                _vm.$set(_vm.loginData, "password", $$v)
              },
              expression: "loginData.password"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "sw-button",
        {
          attrs: {
            loading: _vm.isLoading,
            disabled: _vm.isLoading,
            type: "submit"
          }
        },
        [_vm._v("\n    Log in\n  ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }